@tailwind base;
@tailwind components;
@tailwind utilities;

body::before {
  content: "";
  background-image: url("./contents/Background-bits.png");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(5px);
  animation: glow 5s infinite alternate;
  background-size: cover;
  background-position: center;
}

@keyframes glow {
  from {
    filter: brightness(1.5);
  }
  to {
    filter: brightness(1);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@100&family=Quantico:wght@700&display=swap");

* {
  font-family: "Josefin Slab", serif;
  font-family: "Quantico", sans-serif;
}
